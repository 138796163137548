import React from 'react';
import { Link } from 'react-router-dom';
import convert from 'htmr';
import '../css/moduleProfile.css';

const ProfileOverlay = (props) => {
  return (
    <div className='module-profile__overlay'>
      <div className='container-narrower position-relative'>
        <button type='button' className='btn btn-overlay close' aria-label='Close' onClick={() => props.btnCloseClicked()}></button>
        <button type='button' className='btn btn-overlay previous' aria-label='Previous' onClick={() => props.btnPreviousClicked(props.index)}></button>
        <div className='module-profile__overlay__content'>
          <div className='module-profile module-profile__normal'>
            {props.element.image && <div className='module-profile__image'>
              <img src={props.element.image.path} alt={props.element.title} />
            </div>}
            <div className='module-profile__content'>
              <div className='module-profile__copy'>
                <h2 className='text-white'>{props.element.firstName} {props.element.lastName}{props.element.title && ', '}<span className='module-profile__title'>{props.element.title}</span></h2>
                <div className='module-profile__excerpt text-white'>{convert(props.element.excerpt)}</div>
              </div>
              <div className='module-profile__links'>
                {props.element.path && <Link to={{pathname: props.element.path, state: {props: props.element}}}>Learn more</Link>}
                {props.element.contact && <a className='module-profile__links__contact' href={`mailto:${props.element.contact}`} title={props.element.contact}>Contact</a>}
              </div>
            </div>
          </div>
        </div>
        <button type='button' className='btn btn-overlay next' aria-label='Next' onClick={() => props.btnNextClicked(props.index)}></button>
      </div>
    </div>
  );
}

export default ProfileOverlay;