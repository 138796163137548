import React from 'react';
import { Link } from 'react-router-dom';
import convert from 'htmr';
import '../css/moduleProfile.css';

const Profile = (props) => {
  return (
    <div className={`module-profile ${props.type !== undefined && `module-profile__${props.type}`}`}>
      {props.element.image && <div className='module-profile__image'>
        {props.type === 'thumbnail' ? <button className='btn module-profile__image__btn' onClick={() => props.btnThumbnailClicked()}><img src={props.element.image.path} alt={props.element.title} /></button> : <Link to={{pathname: props.element.path, state: {props: props.element}}}><img src={props.element.image.path} alt={props.element.title} /></Link>}
      </div>}
      <div className='module-profile__content'>
        <div className='module-profile__copy'>
          <h2>{props.element.firstName}{props.element.firstName && props.element.lastName && ' '}{props.element.lastName}{props.element.title && ', '}<span className='module-profile__title'>{props.element.title}</span></h2>
          <div className='module-profile__excerpt'>{convert(props.element.excerpt)}</div>
        </div>
        <div className='module-profile__links'>
          {props.element.path && <Link to={{pathname: props.element.path, state: {props: props.element}}}>Learn more</Link>}
          {props.element.contact && <a className='module-profile__links__contact' href={`mailto:${props.element.contact}`} title={props.element.contact}>Contact</a>}
        </div>
      </div>
    </div>
  );
}

export default Profile;