import React from 'react';
import convert from 'htmr';
import '../css/moduleLocation.css';

const Location = (props) => {
  const colClasses = props.type === 'small' ? ['col col-12 col-md-5', 'col col-12 col-md-7'] : props.type === 'large' ? ['col col-12 col-md-8 order-md-1', 'col col-12 col-md-4 order-md-0'] : ['col', 'col'];

  return (
    <div className={`module-location ${props.type && `module-location__${props.type}`}`}>
      <div className='row'>
        <div className={colClasses[0]}>
          <div className='module-location__map'>{convert(props.element.location.url)}</div>
        </div>
        <div className={colClasses[1]}>
          <div className='module-location__content'>
            <h2>{props.element.title}</h2>
            {props.type === 'large' && props.element.addressExtended ? convert(props.element.addressExtended) : <p>{convert(props.element.address.replace(/(?:\r\n|\r|\n)/g, '<br>'))}</p>}
            <div className='module-location__links'>
              {props.element.phone && <a href={`tel:${props.element.phone}`} title={props.element.phone}>{props.element.phone}</a>}
              {props.element.directions && <a href={props.element.directions} title='Directions' target='_blank' rel='noopener noreferrer'>Get directions</a>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Location;