import React from 'react';
import { Link } from 'react-router-dom';
import '../css/socialLinks.css';

const SocialLinks = (props) => {
  return (
    <div className='link-info-container'>
      <ul className='link-social-container'>
        <li><Link to='/blog' className='link-social link-social__blog' onClick={() => props.linkClicked()}><span className='sr-only'>Blog</span></Link></li>
        <li><a className='link-social link-social__facebook' href='https://www.facebook.com/vccnewhope' title='Facebook' target='_blank' rel='noopener noreferrer'><span className='sr-only'>Facebook</span></a></li>
        <li><a className='link-social link-social__linkedin' href='https://www.linkedin.com/company/villlage-counseling-center' title='LinkedIn' target='_blank' rel='noopener noreferrer'><span className='sr-only'>LinkedIn</span></a></li>
      </ul>
      <ul className='link-contact-container'>
        <li><a className='link-contact' href='tel:18474881999' title='847.488.1999'><small><b>P:</b></small>  847.488.1999</a></li>
        <li><a className='link-contact' href='mailto:info@VCCNewHope.com' title='info@VCCNewHope.com'><small><b>E:</b></small>  info@VCCNewHope.com</a></li>
        <li><a className='link-contact' href='fax:18474889797' title='847.488.9797'><small><b>F:</b></small>  847.488.9797</a></li>
        <li><a className='link-contact link-sitemap' href='/sitemap' title='Sitemap'>Sitemap</a></li>
      </ul>
    </div>
  );
}

export default SocialLinks;