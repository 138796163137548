import React from 'react';
import { Link } from 'react-router-dom';

const NavBtnDropdown = (props) => {
  return (
    <div className='btn-group'>
      <Link to={props.entry.path} className={`nav-link ${props.toggled ? 'active' : ''}`} onClick={() => props.navLinkClicked()}>{props.entry.title}</Link>
      <button type='button' className={`btn dropdown-toggle dropdown-toggle-split ${props.toggled ? 'toggled' : ''}`} data-toggle='dropdown' aria-haspopup='true' aria-expanded={props.toggled} onClick={() => props.navBtnToggleClicked(!props.toggled)}>
        <span className='sr-only'>Toggle Dropdown</span>
      </button>
      <div className={`nav-description ${props.toggled ? 'show': 'hide'}`}>{props.entry.description}</div>
    </div>
  );
}

export default NavBtnDropdown;