import React, { useState } from 'react';
import { callAPI } from '../utils/CallAPI';
import { useInput } from '../utils/InputHook';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';

const Form = (props) => {
  const {value:name, bind:bindName} = useInput('');
  const {value:email, bind:bindEmail} = useInput('');
  const {value:phone, bind:bindPhone} = useInput('');
  const {value:insurance, bind:bindInsurance} = useInput('');
  const {value:message, bind:bindMessage} = useInput('');
  const {value:firstName, bind:bindFirstName} = useInput('');
  const {value:lastName, bind:bindLastName} = useInput('');
  const {value:currentPosition, bind:bindCurrentPosition} = useInput('');
  const {value:address, bind:bindAddress} = useInput('');
  const {value:city, bind:bindCity} = useInput('');
  const {value:state, bind:bindState} = useInput('');
  const {value:zip, bind:bindZip} = useInput('');
  const [confirm, setConfirm] = useState(false);
  const [option1, setOption1] = useState(false);
  const [option2, setOption2] = useState(false);
  const [option3, setOption3] = useState(false);
  const [file, setFile] = useState();
  const [formResult, setFormResult] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const formContactSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const requestHeaders = new Headers();
    requestHeaders.append('Content-Type', 'application/json;charset=UTF-8');
    const requestBody = JSON.stringify({
      form: {
        name: name,
        phone: phone,
        mail: email,
        insurance: insurance,
        message: message,
        confirm_terms_and_conditions: confirm
      }
    });
    try {
      const result = await callAPI({url: `forms/submit/${props.formName}`, method: 'POST', headers: requestHeaders, body: requestBody});
      result && setIsLoading(false);
      if (result.ok) {
        setFormResult(1);
        return (result.response);
      }
      else {
        setFormResult(-1);
        console.log(result);  
        // fetch error
      }  
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  }
  
  const formCareersSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const uploadResult = file && await uploadFile(file);
    const uploadedFile = uploadResult && uploadResult.uploaded.length ? `<a href="https://vccnewhope.com/cockpit/storage/uploads${uploadResult.assets[0].path}">${uploadResult.assets[0].path}</a>` : 'None';
    console.log(uploadResult)
    const requestHeaders = new Headers();
    requestHeaders.append('Content-Type', 'application/json;charset=UTF-8');
    const requestBody = JSON.stringify({
      form: {
        optionDegree1: option1,
        optionDegree2: option2,
        optionDegree3: option3,
        firstName: firstName,
        lastName: lastName,
        currentPosition: currentPosition,
        address: address,
        city: city,
        state: state,
        zip: zip,
        phone: phone,
        mail: email,
        message: message,
        file: uploadedFile
      }
    });
    try {
      const result = await callAPI({url: `forms/submit/${props.formName}`, method: 'POST', headers: requestHeaders, body: requestBody});
      result && setIsLoading(false);
      if (result.ok) {
        setFormResult(1);
        return (result.response);
      }
      else {
        setFormResult(-1);
        console.log(result);  
        // fetch error
      }  
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  }

  const uploadFile = async (file) => {
    const data = new FormData();
    data.append('files[]', file, file.name);
    data.append('meta[folder]', '5f198f7e343665657600031c');
    try {
      const result = await callAPI({url: 'cockpit/addAssets', method: 'POST', body: data});
      console.log(result.response);
      return (result.response);
    } catch (error) {
      console.log(error);
    }
  }

  const formContact = () => {
    return (
      <form onSubmit={(e) => formContactSubmit(e)}>
        <div className='row'>
          <div className='col col-12 col-md-4 form-group'>
            <input id='contactName' type='text' className='form-control' placeholder='Name:' {...bindName} required autoComplete='name' />
          </div>
          <div className='col col-12 col-md-4 form-group'>
            <input id='contactEmail' type='email' className='form-control' placeholder='Email:' {...bindEmail} required autoComplete='email' />
          </div>
          <div className='col col-12 col-md-4 form-group'>
            <input id='contactPhone' type='tel' className='form-control' placeholder='Phone Number:' {...bindPhone} required autoComplete='tel' />
          </div>
        </div>
        <div className='row'>
          <div className='container-narrower'>
            <div className='form-group'>
              <input id='contactInsurance' type='text' className='form-control' placeholder='Insurance Company:' {...bindInsurance} required autoComplete='organization' />
            </div>
            <div className='form-group'>
              <textarea id='contactMessage' type='text' className='form-control' placeholder='Reason for Counseling or Message:' {...bindMessage} required rows='5' />
            </div>
            <div className='form-group'>
              <div className='form-check form-check-inline'>
                <input id='contactConfirm' type='checkbox' className='form-control form-check-input' checked={confirm} onChange={() => setConfirm(!confirm)} required />
                <label className='form-check-label' htmlFor='contactConfirm'>*I acknowledge that I am sharing my private health information (PHI) with Village Counseling Center.</label>
              </div>
            </div>
            {isLoading ? <div className='loading'></div> : <div>
              <div className='form-group text-center text-md-left'>
                {formResult <= 0 && <button type='submit' className='btn btn-cta'>Submit  ›</button>}
              </div>
              <div className='form-group'>
                {formResult === 1 && <div className='alert alert-success' role='alert'>Your message has been sent!</div>}
                {formResult === -1 && <div className='alert alert-danger' role='alert'>An error occured.</div>}
              </div>
            </div>}
          </div>
        </div>
      </form>
    )
  }

  const formCareers = () => {
    return (
      <form onSubmit={(e) => formCareersSubmit(e)}>
        <div className='container-narrower'>
          <div className='form-group'>
            <div className='form-check form-check-inline'>
              <input id='optionDegree1' type='checkbox' className='form-control form-check-input' checked={option1} onChange={() => setOption1(!option1)} />
              <label className='form-check-label' htmlFor='optionDegree1'>PhD / PsyD</label>
            </div>
            <div className='form-check form-check-inline'>
              <input id='optionDegree2' type='checkbox' className='form-control form-check-input' checked={option2} onChange={() => setOption2(!option2)} />
              <label className='form-check-label' htmlFor='optionDegree2'>LCSW</label>
            </div>
            <div className='form-check form-check-inline'>
              <input id='optionDegree3' type='checkbox' className='form-control form-check-input' checked={option3} onChange={() => setOption3(!option3)} />
              <label className='form-check-label' htmlFor='optionDegree3'>LCPC</label>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col col-12 col-md-4 form-group'>
            <input id='careerFirstName' type='text' className='form-control' placeholder='First Name:' {...bindFirstName} required autoComplete='given-name' />
          </div>
          <div className='col col-12 col-md-4 form-group'>
            <input id='careerLastName' type='text' className='form-control' placeholder='Last Name:' {...bindLastName} required autoComplete='family-name' />
          </div>
          <div className='col col-12 col-md-4 form-group'>
            <input id='careerCurrentPosition' type='text' className='form-control' placeholder='Current Position:' {...bindCurrentPosition} required />
          </div>
        </div>
        <div className='row'>
          <div className='col col-12 col-md-4 form-group'>
            <input id='careerAddress' type='text' className='form-control' placeholder='Address:' {...bindAddress} required autoComplete='street-address' />
          </div>
          <div className='col col-12 col-md-4 form-group'>
            <input id='careerCity' type='text' className='form-control' placeholder='City:' {...bindCity} required autoComplete='address-level2' />
          </div>
          <div className='col col-6 col-md-2 form-group'>
            <input id='careerState' type='text' className='form-control' placeholder='State:' {...bindState} required autoComplete='address-level1' />
          </div>
          <div className='col col-6 col-md-2 form-group'>
            <input id='careerZip' type='text' className='form-control' placeholder='ZIP:' {...bindZip} required autoComplete='postal-code' />
          </div>
        </div>
        <div className='row'>
          <div className='container-narrower'>
            <div className='row'>
              <div className='col col-12 col-md-6 form-group'>
                <input id='careerPhone' type='tel' className='form-control' placeholder='Phone Number:' {...bindPhone} required autoComplete='tel' />
              </div>
              <div className='col col-12 col-md-6 form-group'>
                <input id='careerEmail' type='email' className='form-control' placeholder='Email:' {...bindEmail} required autoComplete='email' />
              </div>
            </div>
            <div className='form-group'>
              <textarea id='careerMessage' type='text' className='form-control' placeholder='Why are you interested in working for us?' {...bindMessage} required rows='5' />
            </div>
            {isLoading ? <div className='loading'></div> : <div>
              {formResult <= 0 && <div className='form-group text-center text-md-left'>
                <label htmlFor='careerFile' className='btn btn-cta'>Attach resumé ›</label>
                <input id='careerFile' type='file' className='sr-only' onChange={(e) => setFile(e.target.files[0])} />
              </div>}
              {formResult <= 0 && file && <div className='form-group'><span className='form-file'>{file.name}<button title='Remove file' className='form-file-remove' onClick={() => setFile()}></button></span></div>}
              <GoogleReCaptcha onVerify={token => console.log(token)} />
              {formResult <= 0 && <div className='form-group text-center text-md-left'>
                <button type='submit' className='btn btn-cta'>Submit application  ›</button>
              </div>}
              <div className='form-group'>
                {formResult === 1 && <div className='alert alert-success' role='alert'>Your message has been sent!</div>}
                {formResult === -1 && <div className='alert alert-danger' role='alert'>An error occured.</div>}
              </div>
            </div>}
          </div>
        </div>
      </form>
    )
  }

  const formSelect = () => {
    switch (props.formName) {
      case 'contact': return formContact();
      case 'careers': return formCareers();
      default: return null;
    }
  }

  return (
    formSelect()
  );
}

export default Form;