import React, { useEffect, useState } from 'react';
import Child from './Child';
import SocialLinks from './SocialLinks';
import { callAPI } from '../utils/CallAPI';

const Footer = (props) => {
  const [footers, setFooters] = useState();

  const loadFooters = async () => {
    try {
      const result = await callAPI({url: 'collections/get/footers?populate=-1&populate_module=-1', method: 'GET'});
      if (result.ok) {
        setFooters(result.response);
      }
      else {
        console.log(result);
        // fetch error
      }  
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    props.name && loadFooters();
  }, [props.name]);

  return (
    <footer>
      {footers && footers.entries.length && footers.entries.map(entry => {
        return entry.name === props.name && entry.layout.map((element, index) => {
          return <Child element={element} key={index} />
        })
      })}
      <div className='container-narrower footer-bkg'>
        <SocialLinks linkClicked={() => {return null}} />
        <p className='footer-copy text-center'>Copyright © 2009-2020 VCC, Inc.</p>
      </div>
    </footer>
  );
}

export default Footer;