import React from 'react';
import { Link } from 'react-router-dom';
import HeaderLogo from './HeaderLogo';
import Footer from './Footer';

const Sitemap = (props) => {
  console.log(props);
  
  return (
    <React.Fragment>
      <main>
        <HeaderLogo home={false} />
        <section className='container-narrower'>
          <h2>Sitemap</h2>
          <ul className='one-col'>
            {props.menuItems && props.menuItems.length && props.menuItems.map((entry, index) => {
              return (
                <li key={index}><Link to={entry.path}>{entry.title}</Link>
                  {entry.path === '/your-team' && <ul className='one-col'>
                    {props.profiles && props.profiles.length && props.profiles.map((entry, index) => {
                      return entry.visible && <li key={index}><Link to={entry.path}>{entry.firstName}{entry.firstName && entry.lastName && ' '}{entry.lastName}</Link></li>
                    })}
                  </ul>}
                </li>
              )
            })}
            <li><Link to='/blog'>Blog</Link></li>
          </ul>
        </section>
      </main>
      <Footer name='Global' />
    </React.Fragment>
  );
}

export default Sitemap;