import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import algoliasearch from 'algoliasearch/lite';
import convert from 'htmr';
import HeaderLogo from './HeaderLogo';
import Footer from './Footer';
import '../css/blogPage.css';

const SearchPage = (props) => {
  const [searchResults, setSearchResults] = useState();

  document.body.classList.remove('home');
  document.body.classList.add('bkg-short');

  const search = async (content) => {
    const client = algoliasearch('37EE2F1MJ6', '928d801877758cbe203202b74de46484');
    const queries = [{
      indexName: 'pages',
      query: content,
      params: {
        hitsPerPage: 3
      }
    }, {
      indexName: 'posts',
      query: content,
      params: {
        hitsPerPage: 3
      }
    }, {
      indexName: 'profiles',
      query: content,
      params: {
        hitsPerPage: 3
      }
    }];
    const response = await client.multipleQueries(queries);
    response && response.results && setSearchResults(response.results);
  }

  const iterate = (obj) => {
    const result = obj.map(item => {
      if (item.settings && item.settings.text && item.settings.text.matchLevel === 'full') {
        return convert(item.settings.text.value.replace(/\r?\n|\r/g, '; '));
      }
      else {
        return item.children && item.children.length && iterate(item.children);
      }
    });
    return result !== undefined && result;
  }

  useEffect(() => {
    props.location.state && props.location.state.refferer && search(props.location.state.refferer);
  }, [props.location]);

  return (
    <React.Fragment>
      <main>
        <HeaderLogo home={false} />
        <section className='container-narrower search-results'>
          <h2>Search Results</h2>
          {searchResults && searchResults.length && searchResults.map(result => {
            return result.hits.length > 0 && result.hits.map((hit, index) => {
              const linkTitle = hit.title || `${hit.firstName}${hit.lastName ? ` ${hit.lastName}` : ''}`;
              return (
                (hit.path || hit.slug) && <p key={index}><Link to={hit.path || hit.slug}>{linkTitle}</Link>
                  <br />
                  {hit._snippetResult && typeof(hit._snippetResult) === 'object' && Object.keys(hit._snippetResult).map((key) => {
                    const snippetResult = hit._snippetResult[key];
                    if (snippetResult.matchLevel === 'full') {
                      return convert(snippetResult.value);
                    }
                    else {
                      return snippetResult.length && iterate(snippetResult);
                    }
                  })}
                </p>
              );
            })
          })}
        </section>
      </main>
      <Footer name='Global' />
    </React.Fragment>
  );
}

export default SearchPage;
