import React, { useState } from 'react';
import convert from 'htmr';
import Profile from '../modules/Profile';
import Location from '../modules/Location';
import Form from '../modules/Form';
import ProfileOverlay from './ProfileOverlay';

const Child = (props) => {
  const [overlay, setOverlay] = useState();
  overlay ? document.body.classList.add('modal-open') : document.body.classList.remove('modal-open');

  const getPreviousProfile = (array, index) => {
    const newIndex = index === 0 ? array.length - 1 : index - 1;
    setOverlay({element: array[newIndex], index: newIndex});
  }

  const getNextProfile = (array, index) => {
    const newIndex = index === array.length - 1 ? 0 : index + 1;
    setOverlay({element: array[newIndex], index: newIndex});
  }

  const element = () => {
    const elementId = props.element.settings && (props.element.settings.id || null);
    const elementClass = props.element.settings && (props.element.settings.class || null);
    switch (props.element.component) {
      case 'image':
        return props.element.settings.image && <img id={elementId} className={elementClass} src={props.element.settings.image.path} alt='' />;
      case 'heading':
        const TagName = props.element.settings.tag || 'div';
        return <TagName id={elementId} className={elementClass}>{props.element.settings.text}</TagName>;
      case 'text':
        return convert(props.element.settings.text);
      case 'button':
        return <a id={elementId} className={elementClass} href={props.element.settings.url}>{props.element.settings.text}</a>;
      case 'grid':
        return (
          <div id={elementId} className={elementClass}>{props.element.columns && props.element.columns.length > 0 && props.element.columns.map((element, index) => {
            return <div className={element.settings.class} key={index}><Child key={index} element={element} /></div> ;
          })}</div>
        );
      case 'section':
        return (
          <section id={elementId} className={elementClass}>{props.element.children && props.element.children.length > 0 && props.element.children.map((element, index) => {
            return <Child key={index} element={element} />;
          })}</section>
        );
      case 'moduleLink':
        return (
          <div id={elementId} className={elementClass}>{props.element.settings.collection && props.element.settings.collection.length > 0 && props.element.settings.collection.map((element) => {
            const collectionArray = [];
            element && Object.keys(element).map((key) => {
              const child = element[key];
              collectionArray.concat(child);
              return child.visible && collectionArray.push(child);
            });
            collectionArray.sort((a, b) => (a._o > b._o) ? 1 : -1);
            return collectionArray && collectionArray.length && collectionArray.map((element, index) => {
              return <div className='col col-12 col-sm-6 col-lg-4' key={index}><Profile element={element} type={props.element.settings.type} /></div>
            })
          })}</div>
        );
      case 'profilesLink':
        const sortedCollectionLink = props.element.settings.collectionLink && props.element.settings.collectionLink.length > 0 && props.element.settings.collectionLink.filter(i => i.visible).sort((a, b) => (a._o > b._o) ? 1 : -1);
        if (props.element.settings.type === 'random') {
          const randomItem = sortedCollectionLink[Math.floor(Math.random() * sortedCollectionLink.length)];
          return <Profile element={randomItem} key={0} type={'side-by-side'} btnThumbnailClicked={null} />
        }
        else {
          return (
            <div id={elementId} className={elementClass}>{sortedCollectionLink.length && sortedCollectionLink.map((element, index) => {
              return <Profile element={element} key={index} type={props.element.settings.type} btnThumbnailClicked={() => setOverlay({element: element, index: index})} />
            })}
              {overlay && <ProfileOverlay element={overlay.element} index={overlay.index} btnCloseClicked={() => setOverlay()} btnPreviousClicked={(index) => getPreviousProfile(sortedCollectionLink, index)} btnNextClicked={(index) => getNextProfile(sortedCollectionLink, index)} />}
            </div>
          )
        }
      case 'fileLink':
        return <a id={elementId} className={elementClass} href={props.element.settings.file}>{props.element.settings.title}</a>;
      case 'locationLink':
        const sortedLocationLink = props.element.settings.collectionLink && props.element.settings.collectionLink.length > 0 && props.element.settings.collectionLink.sort((a, b) => (a._o > b._o) ? 1 : -1);
        return <div id={elementId} className={elementClass}>{sortedLocationLink.length && sortedLocationLink.map((element, index) => {
          return <Location element={element} key={index} type={props.element.settings.type} />
        })}</div>;
      case 'footerLink':
        return (
          <div id={elementId} className={elementClass}>{props.element.settings.collectionLink && props.element.settings.collectionLink.length > 0 && props.element.settings.collectionLink.map((element, index) => {
            return <Child key={index} element={element.layout[0]} />;
          })}</div>
        );
      case 'formLink':
        return <div id={elementId} className={elementClass}>{props.element && <Form formName={props.element.settings.form[0]._modulelink} />}</div>
      case 'googleMapiframe':
        return <div id={elementId} className={elementClass}>{convert(props.element.settings.map.url)}</div>;
      default:
        return (
          props.element.children ? props.element.children.length > 0 && props.element.children.map((element, index) => {
            return <Child key={index} element={element} />;
          }) : null
        );
    }
  }

  return (
    element()
  );
}

export default Child;