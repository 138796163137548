import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import convert from 'htmr';
import HeaderLogo from './HeaderLogo';
import Footer from './Footer';

const ProfilePage = (props) => {
  document.body.classList.remove('home');
  document.body.classList.add('bkg-short');

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const locations = () => {
    return props.content.locations && props.content.locations.length > 0 && <div>
      <h2>Location{props.content.locations.length > 1 && 's'}</h2>
      {props.content.locations.map((entry, index) => {
        return <div className='mb-2' key={index}><Link to='/contact'>{entry.display}</Link></div>
      })}
    </div>
  }

  return (
    <React.Fragment>
      <main>
        <HeaderLogo home={props.content.home} />
        <section className='container-narrower'>
          <div className='row'>
            <div className='col col-12 col-md-6'>
              {props.content.image && <div className='profile-page__image element-border mt-3'>
                <img src={props.content.image.path} alt={props.content.title} />
              </div>}
              <div className='d-none d-md-block'>{locations()}</div>
            </div>
            <div className='col col-12 col-md-6'>
              <h2>{props.content.firstName}{props.content.firstName && props.content.lastName && ' '}{props.content.lastName}, {props.content.title}</h2>
              {convert(props.content.excerpt)}
              {props.content.contact ? <a href={`mailto:${props.content.contact}`} title={props.content.contact}>Connect with {props.content.firstName}</a> : <Link to='/contact'>Request an appointment with {props.content.firstName}</Link>}
              <div className='d-md-none'>{locations()}</div>
            </div>
          </div>
        </section>
        {props.content.video && <section className='container-narrow px-0 px-md-3'>
          <div className='container-narrower'>
            <h2>Watch the video below and see what {props.content.firstName} has to say</h2>
          </div>
          <div className='iframe-youtube-embed'>
            <iframe src={props.content.video} title={`Embedded video for ${props.content.firstName} ${props.content.lastName}`}></iframe>
          </div>
        </section>}
        {props.content.intro && props.content.content && <section className='container-narrower'>
          <h2>Quick Q&A</h2>
          <p>{props.content.intro}</p>
          <div className='column-count-text'>
            {convert(props.content.content)}
          </div>
          {props.content.contactLinkFooter && <p className='mt-4'>Interested in getting to know {props.content.firstName}? <Link to='/contact'>Contact us today to request an appointment.</Link></p>}
        </section>}
      </main>
      <Footer name='Global' />
    </React.Fragment>
  );
}

export default ProfilePage;