import React from 'react';
import { useInput } from '../utils/InputHook';
import '../css/searchBar.css';

const SearchBar = (props) => {
  const {value:search, bind:bindSearch} = useInput('');

  const searchSubmit = (e) => {
    e.preventDefault();
    const searchTrimmed = search.replace(/[^\w\s]/gi, '');
    searchTrimmed.length >= 3 && props.submitSearch(searchTrimmed);
  }

  return (
    <div className='searchbar'>
      <form className='form-inline flex-nowrap' onSubmit={(e) => searchSubmit(e)}>
        <input id='search' type='text' className='form-control flex-grow-1' placeholder='Search:' {...bindSearch} autoFocus />
        <button type='submit' className='btn navbar-btn navbar-btn__search' aria-label='Search' disabled={search.length < 3}><span className='sr-only'>Search</span></button>
      </form>
    </div>
  )
}

export default SearchBar;