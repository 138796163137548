import React, { useEffect } from 'react';
import Child from './Child';
import Footer from './Footer';
import HeaderLogo from './HeaderLogo';

const Page = (props) => {
  document.body.classList.remove('bkg-short');
  props.content.home ? document.body.classList.add('home') : document.body.classList.remove('home');

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <React.Fragment>
      <main>
        <HeaderLogo home={props.content.home} />
        {props.content.layout && props.content.layout.length && props.content.layout.map((element, index) => {
          return <Child key={index} element={element} />
        })}
      </main>
      <Footer />
    </React.Fragment>
  );
}

export default Page;