import React from 'react';
import { Link } from 'react-router-dom';
import siteLogo from '../images/logo-village-counseling-center.png';

const HeaderLogo = (props) => {
  return (
    <div className='container-narrow'>
      <div className='logo-container'>
        <div className='row'>
          <div className='col col-12 col-md-6 text-center text-md-left'>
            <Link to='/'><img src={siteLogo} alt='Village Counseling Center - New Hope for a better life' /></Link>
          </div>
          {!props.home && <div className='col col-12 col-md-6 text-center'>
            <Link to='/contact' className='btn btn-cta'>Schedule an Appointment  ›</Link>
          </div>}
        </div>
      </div>
    </div>
  )
}

export default HeaderLogo;