import React, { useEffect, useState } from 'react';
import { BrowserRouter , Route, Switch, Redirect } from 'react-router-dom';
import { callAPI } from './utils/CallAPI';
import Navbar from './components/NavBar';
import Page from './components/Page';
import ProfilePage from './components/ProfilePage';
import BlogPage from './components/BlogPage';
import SearchPage from './components/SearchPage';
import Sitemap from './components/Sitemap';
import NoMatch from './components/NoMatch';

const App = () => {
  const [menu, setMenu] = useState();
  const [pages, setPages] = useState();
  const [profiles, setProfiles] = useState();
  const [posts, setPosts] = useState();
  const [searchContent, setSearchContent] = useState();

  const loadMenu = async () => {
    try {
      const result = await callAPI({url: 'collections/get/menu', method: 'GET'});
      if (result.ok) {
        setMenu(result.response);
      }
      else {
        console.log(result);
        // fetch error
      }  
    } catch (error) {
      console.log(error);
    }
  }
  
  const loadPages = async () => {
    try {
      const result = await callAPI({url: 'collections/get/pages?populate=-1&populate_module=-1', method: 'GET'});
      if (result.ok) {
        setPages(result.response);
      }
      else {
        console.log(result);
        // fetch error
      }  
    } catch (error) {
      console.log(error);
    }
  }

  const loadProfiles = async () => {
    try {
      const result = await callAPI({url: 'collections/get/profiles', method: 'GET'});
      if (result.ok) {
        setProfiles(result.response);
      }
      else {
        console.log(result);
        // fetch error
      }  
    } catch (error) {
      console.log(error);
    }
  }

  const loadPosts = async () => {
    try {
      const result = await callAPI({url: 'collections/get/posts', method: 'GET'});
      if (result.ok) {
        setPosts(result.response);
      }
      else {
        console.log(result);
        // fetch error
      }  
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    loadMenu();
    loadPages();
    loadProfiles();
    loadPosts();
  }, []);

  return (
    <BrowserRouter>
      <Navbar menu={menu} submitSearch={(content) => setSearchContent(content)} />
      <Switch>
        {pages && pages.total > 0 && pages.entries.length && pages.entries.map((entry, index) => {
          return <Route exact key={index} path={entry.path} render={(props) => <Page {...props} content={entry} />} />
        })}
        {profiles && profiles.total > 0 && profiles.entries.length && profiles.entries.map((entry, index) => {
          return <Route exact key={index} path={entry.path} render={(props) => <ProfilePage {...props} content={entry} />} />
        })}
        {posts && posts.total > 0 && posts.entries.length && posts.entries.map((entry, index) => {
          return entry.published && <Route exact key={index} path={`/${entry.slug}`} render={(props) => <BlogPage {...props} post={entry} />} />
        })}
        <Route exact path={'/blog'} render={() => <BlogPage posts={posts} />} />
        <Route exact path={'/search'} component={SearchPage} />
        {pages && profiles && posts && <Route exact path={'/sitemap'} render={() => <Sitemap menuItems={menu.entries} profiles={profiles.entries} posts={posts.entries} />} />}
        <Route component={NoMatch} />
      </Switch>
      {searchContent && <Redirect push to={{pathname: '/search', state: {refferer: searchContent}}} />}
    </BrowserRouter>
  );
}

export default App;
