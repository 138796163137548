import React from 'react';
import HeaderLogo from './HeaderLogo';
import Footer from './Footer';

const NoMatch = () => {
  document.body.classList.remove('home');
  document.body.classList.add('bkg-short');

  return (
    <React.Fragment>
      <main>
        <HeaderLogo home={false} />
        <section className='container-narrower fade-in'>
          <h2>Page not found.</h2>
        </section>
      </main>
      <Footer name='Global' />
    </React.Fragment>
  );
}

export default NoMatch;
