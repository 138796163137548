import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';

ReactDOM.render(
  <GoogleReCaptchaProvider reCaptchaKey='6Ldq8PgUAAAAAFXCKwKK21gd44mcK_6LxguFak9-'>
    <GoogleReCaptcha onVerify={token => console.log(token)} />
    <App />
  </GoogleReCaptchaProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
