export const callAPI = async ({url, method, headers, body}) => {
  try {
    const requestHeaders = headers || new Headers();
    requestHeaders.append('Cockpit-Token', 'ecd6c2a9ac813c8c23cc87606440c5');
    const response = await fetch(`https://www.vccnewhope.com/cockpit/api/${url}`, {
      method: method,
      headers: requestHeaders,
      body: body
    });
    const result = await response.json();
    return {ok: response.ok, response: result};
  } catch (error) {
    return {ok: false, response: error};
  }
};