import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import SearchBar from './SearchBar';
import NavBtnDropdown from './NavBtnDropdown';
import SocialLinks from './SocialLinks';
import menuDefault from '../images/menu-default.jpg';
import menuDefaultMobile from '../images/menu-default-mobile.jpg';
import '../css/navBar.css';

const Navbar = (props) => {
  const [navVisible, setNavVisible] = useState(false);
  const [searchVisible, setSearchVisible] = useState(false);
  const [activeHover, setActiveHover] = useState();

  navVisible ? document.body.classList.add('modal-open') : document.body.classList.remove('modal-open');

  return (
    <header className={`fixed-top ${navVisible ? 'h-100': ''}`}>
      <div className='container-narrow position-relative'>
        <div className='navbar'>
          <div className='mr-auto'>
            <a className='btn navbar-btn navbar-btn__share' href={`mailto:?subject=${window.location.href}`} title='Share via email'><span className='sr-only'>Share via email</span></a>
            <a className='btn navbar-btn navbar-btn__call' href='tel:18474881999' title='847.488.1999'><span className='sr-only'>847.488.1999</span></a>
            <button type='button' className={`btn navbar-btn navbar-btn__search ${searchVisible ? 'active' : ''}`} aria-label='Search' onClick={() => {setSearchVisible(!searchVisible); setNavVisible(false)}}></button>
            {searchVisible && <SearchBar submitSearch={(content) => {setNavVisible(false); props.submitSearch(content); setSearchVisible(false)}} closeSearchbar={() => setSearchVisible(false)} />}
          </div>
          <button type='button' className={`btn btn-toggle ${navVisible ? 'expanded' : ''}`} onClick={() => {setNavVisible(!navVisible); setSearchVisible(false); setActiveHover()}} aria-controls='navMain' aria-expanded={navVisible} aria-label='Toggle navigation'></button>
        </div>
      </div>
      <div className={`nav-container container-fluid collapse ${navVisible ? 'show': 'hide'}`} id='navMain'>
        <div className='row'>
          <div className='col col-12 col-lg-4 p-0'>
            <div className='d-none d-lg-block'>
              {props.menu && props.menu.total > 0 && props.menu.entries.length && props.menu.entries.map((entry, index) => {
                return <img className='nav-container-image' src={entry.image.path} alt='' key={index} />
              })}
              <img src={activeHover ? activeHover.image ? activeHover.image.path : menuDefault : menuDefault} alt='Village Counseling Center' aria-hidden='true' />
            </div>
            <div className='d-lg-none'>
              {props.menu && props.menu.total > 0 && props.menu.entries.length && props.menu.entries.map((entry, index) => {
                return <img className='nav-container-image' src={entry.imageMobile.path} alt='' key={index} aria-hidden='true' />
              })}
              <img src={activeHover ? activeHover.imageMobile ? activeHover.imageMobile.path : menuDefaultMobile : menuDefaultMobile} alt='Village Counseling Center' />
            </div>
          </div>
          <div className='col col-12 col-lg-8 p-0'>
            <div className='nav-wrapper'>
              <div className='d-none d-lg-block'>
                <nav className='nav'>
                  {props.menu && props.menu.total > 0 && props.menu.entries.length && props.menu.entries.map((entry, index) => {
                    return <Link key={index} to={entry.path} className='nav-link' onClick={() => setNavVisible(false)} onMouseEnter={() => setActiveHover(entry)} onMouseLeave={() => setActiveHover()}>{entry.title}</Link>
                  })}
                </nav>
                <div className='nav-description'>{activeHover && activeHover.description}</div>
              </div>
              <nav className='nav-mobile'>
                {props.menu && props.menu.total > 0 && props.menu.entries.length && props.menu.entries.map((entry, index) => {
                  return <NavBtnDropdown key={index} entry={entry} toggled={activeHover ? activeHover._id === entry._id ? true : false : false} navLinkClicked={() => {setNavVisible(false); setActiveHover()}} navBtnToggleClicked={(active) => {active ? setActiveHover(entry) : setActiveHover()}} />;
                })}
              </nav>
              <SocialLinks linkClicked={() => setNavVisible(false)} />
            </div>
          </div>
        </div>
      </div>
      <div className={`nav-overlay ${navVisible ? 'show' : ''}`} onClick={() => setNavVisible(false)}></div>
    </header>
  );
}

export default Navbar;