import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import convert from 'htmr';
import HeaderLogo from './HeaderLogo';
import Footer from './Footer';
import '../css/blogPage.css';

const BlogPage = (props) => {
  const [currentPage, setCurrentPage] = useState(0);

  document.body.classList.remove('home');
  document.body.classList.add('bkg-short');

  const date = props.post && new Date(props.post._created * 1000);
  const published = props.posts && props.posts.total > 0 && props.posts.entries.length && props.posts.entries.filter(i => i.published);
  const nbPages = published && Math.ceil(published.length / 5);

  return (
    <React.Fragment>
      <main>
        <HeaderLogo home={false} />
        <section className='container-narrower'>
          {published && published.length && published.slice(currentPage * 5, currentPage * 5 + 5).map((entry, index) => {
            const date = new Date(entry._created * 1000);
            return (
              entry.published && <div className='blog-post' key={index}>
                {entry.title && <h2>{entry.title}</h2>}
                {date && <h3>Published {date.toLocaleDateString('en-US')}</h3>}
                {entry.content && <div className='blog-post__excerpt'>{convert(entry.content)}</div>}
                {entry.slug && <Link to={`/${entry.slug}`}>Read more</Link>}
              </div>
            );
          })}
          {props.post && <div className='blog-post'>
            <h2>{props.post.title}</h2>
            {date && <h3>Published {date.toLocaleDateString('en-US')}</h3>}
            {props.post && convert(props.post.content)}
            <Link to='/blog'>Back to posts</Link>
          </div>}
          {published && published.length > 0 && <nav>
            <ul className='pagination'>
              <li className={`page-item ${currentPage === 0 ? 'disabled' : ''}`}><button className='page-link' onClick={() => setCurrentPage(currentPage - 1)}>Previous</button></li>
              {nbPages && [...Array(nbPages)].map((x, i) => {
                return <li className={`page-item ${currentPage === i ?  'disabled' : ''}`} key={i}><button className='page-link' onClick={() => setCurrentPage(i)}>{i + 1}</button></li>
              })}
              <li className={`page-item ${published.length < 5 || (currentPage + 1) * 5 >= published.length ? 'disabled' : ''}`}><button className='page-link' onClick={() => setCurrentPage(currentPage + 1)}>Next</button></li>
            </ul>
          </nav>}
        </section>
      </main>
      <Footer name='Global' />
    </React.Fragment>
  );
}

export default BlogPage;
